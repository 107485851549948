dmx.Component('bs4-modal', {

  attributes: {
    nobackdrop: {
      type: Boolean,
      default: false,
    },

    nocloseonclick: {
      type: Boolean,
      default: false,
    },

    nokeyboard: {
      type: Boolean,
      default: false,
    },

    nofocus: {
      type: Boolean,
      default: false,
    },

    show: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    toggle () {
      jQuery(this.$node).modal('toggle');
    },

    show () {
      jQuery(this.$node).modal('show');
    },

    hide () {
      jQuery(this.$node).modal('hide');
    },

    update () {
      jQuery(this.$node).modal('handleUpdate');
    },
  },

  events: {
    show: Event,
    shown: Event,
    hide: Event,
    hidden: Event,
  },

  render (node) {
    this.$parse();

    jQuery(node).on('show.bs.modal', this.dispatchEvent.bind(this, 'show'));
    jQuery(node).on('shown.bs.modal', this.dispatchEvent.bind(this, 'shown'));
    jQuery(node).on('hide.bs.modal', this.dispatchEvent.bind(this, 'hide'));
    jQuery(node).on('hidden.bs.modal', this.dispatchEvent.bind(this, 'hidden'));

    this._modal();
  },

  performUpdate (props) {
    if (JSON.stringify(props) != JSON.stringify(this.props)) {
      this._modal();
    }
  },

  destroy () {
    jQuery(this.$node).off('.bs.modal');
    jQuery(this.$node).modal('dispose');
  },

  _modal () {
    jQuery(this.$node).modal({
      backdrop: (!this.props.nobackdrop && !!this.props.nocloseonclick ? 'static' : !this.props.nobackdrop),
      keyboard: !this.props.nokeyboard,
      focus: !this.props.nofocus,
      show: !!this.props.show
    });
  },

});
